<template>
  <div class="information">
    <!-- <van-nav-bar title="完善信息" /> -->
    <div>
      <div class="we-flex-col we-justify-content-center ">
        <van-image width="100" height="100" round :src="info.headImage" class="imgcenter" />
      </div>
      <van-cell-group>
        <van-cell title="姓名" :value="info.realName" />
        <van-cell title="身份证" :value="info.cardNoStr" />
        <van-cell title="性别" :value="info.sex == 0 ?'女':'男'" />
        <van-cell title="年龄" :value="info.age" />
        <van-cell title="联系电话" :value="info.phone" />

      </van-cell-group>
     
      <div class="position">
        <div class="buttton-lg" @click="handleOut">注销登录</div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  Button,
  Cell,
  Image as VanImage,
  Uploader,CellGroup
} from "vant";
import options from "../../assets/cities.js";
import nationData from "../../assets/nation.js";

import { getUserinfo, updateInfo } from "@api/user";
import { logout } from "@api/system";
export default {
  name: "information",
  data() {
    return {
      info: "",
      show: false,
      areaList: options, //籍贯
      showSex: false,
      showNation: false,
      showBlood: false,
      columns: ["女", "男"], //性别
      nationData: nationData, //民族
      bloodData: ["A型", "B型", "AB型", "O型"], //血型
    };
  },
  components: {
    [Button.name]: Button,
    [Cell.name]: Cell,
    [VanImage.name]: VanImage,
    [Uploader.name]: Uploader,
    [CellGroup.name]: CellGroup,

  },

  methods: {
    //注销登录
    handleOut() {
      logout().then((res) => {
        if (res.respCode == 200) {
          this.$toast("已退出");
          window.localStorage.setItem("isLogin", false);
          // window.localStorage.removeItem("token");
          // window.localStorage.removeItem("userinfo");
          // window.localStorage.removeItem("scanurl");
         window.localStorage.clear();
          this.$router.push({name:'login'})
        } else {
          this.$toast(res.respMsg);
        }
      });
    },
    //更新保存
    onSubmit(values) {
      // console.log("submit", values);
      updateInfo(this.info).then((res) => {
        if (res.respCode == 200) {
          this.$toast("保存成功");
        } else {
          this.$toast(res.respMsg);
        }
      });
    },
    getCode() {
      this.showcut = false;
      //  this.$refs.countDown.start();
    },
    finish() {
      this.showcut = true;
    },
    changeImage() {},
    showPopup() {
      this.show = true;
    },
    pickcardNoStr(picker) {
      let val = picker.getValues();
      this.info.nativePlace = val[0].name + "-" + val[1].name;
    },
    //性别选择
    onConfirm(value, index) {
      // this.$toast(`当前值：${value}, 当前索引：${index}`);
      this.info.sex = index;
      this.showSex = false;
    },
    //选择民族
    nationConfirm(value, index) {
      this.info.nation = value;
      this.showNation = false;
    },
    //选择血型
    bloodConfirm(value, index) {
      this.info.bloodType = value;
      this.showBlood = false;
    },
    //获取个人信息
    ajax_getUserInfo() {
      getUserinfo().then((res) => {
        if (res.respCode == 200) {
          this.info = res.result;
        } else {
          this.$toast(res.respMsg);
        }
      });
    },
  },
  mounted() {
    this.ajax_getUserInfo();
  },
};
</script>
<style lang="less" scope>
.cutdown {
  font-size: 12px;
  color: #999;
}
.van-count-down {
  display: inline;
}
.description {
  padding: 15px;
  color: #999;
  background-color: #f5f5f5;
  margin: 0;
}
.van-uploader {
  width: 100%;
  .van-uploader__input-wrapper {
    width: 100%;
  }
}
.imgcenter {
  margin: 20px auto 20px;
}
.position {
  position: absolute;
  bottom: 75px;
  left: 15px;
  right:15px;
}
</style>