import api from "./api";

//获取个人信息
export const getUserinfo = (params) => {
    return api.get(`/customer/info?time=${new Date().getTime()}`,params)
};
//修改个人信息
export const updateInfo = (params) => {
    return api.post(`/customer/update`,params)
};
//修改手机号
export const updatePhone = (params) => {
    return api.post(`/customer/updatePhone`,params)
};

//微信code
export const oauthLogin = (params) => {
    return api.post(`/oauth/login`,params)
};



